import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MeuPerfilPageRoutingModule } from './meu-perfil-routing.module';
import { MeuPerfilPage } from './meu-perfil.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MeusContatosPageModule } from '../meus-contatos/meus-contatos.module';
import { MeusVeiculosPageModule } from '../meus-veiculos/meus-veiculos.module';
import { MeuPerfilEditarPageModule } from '../meu-perfil-editar/meu-perfil-editar.module';
import { FamiliarCreatePageModule } from '../familiar-create/familiar-create.module';
import { FamiliarEditPageModule } from '../familiar-edit/familiar-edit.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    PipesModule,
    MeuPerfilPageRoutingModule,
    MeusContatosPageModule,
    MeusVeiculosPageModule,
    MeuPerfilEditarPageModule,
    FamiliarCreatePageModule,
    FamiliarEditPageModule,
  ],
  declarations: [MeuPerfilPage]
})
export class MeuPerfilPageModule {}
