import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiRequestService } from "./api-request.service";

@Injectable({
  providedIn: "root",
})
export class MinhacontaService {
  private reloadSubject: BehaviorSubject<any>;
  public reloadData: Observable<any>;

  constructor(private apiRequest: ApiRequestService) {
    this.reloadSubject = new BehaviorSubject("");
    this.reloadData = this.reloadSubject.asObservable();
  }

  public get reloadDataValue() {
    return this.reloadSubject.value;
  }

  public reload() {
    this.reloadSubject.next({});
  }

  async permissions() {
    return this.apiRequest
      .apiServer("minhaconta/permissions")
      .then((response) => {
        return response.data;
      });
  }

  async get() {
    return this.apiRequest.apiServer("minhaconta").then((response) => {
      return response.data;
    });
  }

  async parentescos() {
    return this.apiRequest
      .apiServer("minhaconta/parentescos")
      .then((response) => {
        return response.data;
      });
  }

  async moradores() {
    return this.apiRequest
      .apiServer("minhaconta/moradores")
      .then((response) => {
        return response.data;
      });
  }

  async updateFoto(params) {
    return await this.apiRequest
      .apiServer("minhaconta/updateFoto", params, "POST")
      .then((response) => {
        this.reload();
        return response.data;
      })
      .catch((response) => {
        throw response.data;
      });
  }

  async update(params) {
    return await this.apiRequest
      .apiServer("minhaconta", params, "PUT")
      .then((response) => {
        this.reload();
        return response.data;
      })
      .catch((response) => {
        throw response.data;
      });
  }

  async updateFamiliar(id, params) {
    return await this.apiRequest
      .apiServer("minhaconta/updateFamiliar/" + id, params, "PUT")
      .then((response) => {
        this.reload();
        return response.data;
      })
      .catch((response) => {
        throw response.data;
      });
  }

  async saveFamiliar(params) {
    return await this.apiRequest
      .apiServer("minhaconta/saveFamiliar", params, "POST")
      .then((response) => {
        this.reload();
        return response.data;
      })
      .catch((response) => {
        throw response.data;
      });
  }
}
