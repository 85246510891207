import { Component, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { MinhacontaService } from "src/app/services/minhaconta.service";
import { StorageService } from "src/app/services/storage.service";
import { FamiliarCreatePage } from "../familiar-create/familiar-create.page";
import { FamiliarEditPage } from "../familiar-edit/familiar-edit.page";
import { MeuPerfilEditarPage } from "../meu-perfil-editar/meu-perfil-editar.page";
import { MeusContatosPage } from "../meus-contatos/meus-contatos.page";
import { MeusVeiculosPage } from "../meus-veiculos/meus-veiculos.page";
import { NotificacoesConfigPage } from "../notificacoes/notificacoes-config/notificacoes-config.page";

@Component({
  selector: "app-meu-perfil",
  templateUrl: "./meu-perfil.page.html",
  styleUrls: ["./meu-perfil.page.scss"],
})
export class MeuPerfilPage implements OnInit {
  foto: string = "assets/img/avatarDefault.png";
  params = {
    foto: "",
  };
  loader;
  familiaresSkeleton: any = new Array<any>(10);
  familiares: any = [];
  perfil: any = {};
  familiaresLoaded: Boolean = false;
  canAddFamiliar: Boolean = false;
  condominio: any = {};
  editFoto: Boolean = false;
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private storageService: StorageService,
    private minhacontaService: MinhacontaService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.condominio = this.storageService.get("usuario_condominio").condominio;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  ionViewWillEnter() {
    this.setup();
    this.loadPermissions();
  }

  async setup() {
    await this.loadMinhaConta();
  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {
        const {data} = response;
        this.editFoto = data.morador_foto_perfil === 1 ? true : false;
      })
      .catch((err) => {});
  }

  async loadMinhaConta() {
    await this.minhacontaService
      .get()
      .then((data) => {
        this.perfil = data;
        this.canAddFamiliar =
          this.condominio.cadastra_familiar && this.perfil.parentesco.id === 1
            ? true
            : false;
        if (this.condominio.id_servidor !== 2) {
          if (data.foto && data.foto.foto) {
            this.foto = data.foto.foto;
          }
        }
        if (this.perfil.parentesco && this.perfil.parentesco.id === 1) {
          this.loadFamiliares();
        } else {
          this.familiaresLoaded = true;
        }
      })
      .catch((err) => {});
  }

  async loadFamiliares() {
    await this.minhacontaService
      .moradores()
      .then((data) => {
        this.familiares = data;
      })
      .catch((err) => {});

    this.familiaresLoaded = true;
  }

  async responseAvatar(picture) {
    this.params.foto = picture.base64;
    this.foto = picture.base64;
    this.loader = await this.loadingController.create({
      message: "Atualizando foto do perfil...",
    });
    await this.loader.present();
    await this.minhacontaService
      .updateFoto({ foto: this.foto })
      .then((data) => {})
      .catch((err) => {});
    this.loader.dismiss();
  }

  async modalMeusContato() {
    const modal = await this.modalController.create({
      component: MeusContatosPage,
    });
    await modal.present();
  }

  async modalMeusVeiculos() {
    const modal = await this.modalController.create({
      component: MeusVeiculosPage,
    });
    await modal.present();
  }

  async modalMeuPerfilEdit() {
    const modal = await this.modalController.create({
      component: MeuPerfilEditarPage,
      componentProps: { morador: this.perfil },
    });
    await modal.present();
    modal.onWillDismiss().then(async (response) => {
      if (response.data && response.data.status) {
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Perfil atualizado.",
          buttons: ["OK"],
        });
        alert.present();
        await this.loadMinhaConta();
      }
    });
  }

  async modalFamiliarCreate() {
    const modal = await this.modalController.create({
      component: FamiliarCreatePage,
    });
    await modal.present();
    modal.onWillDismiss().then(async (response) => {
      if (response.data && response.data.status) {
        await this.loadFamiliares();
      }
    });
  }

  async modalFamiliarEdit(morador) {
    if (this.canAddFamiliar) {
      const modal = await this.modalController.create({
        component: FamiliarEditPage,
        componentProps: { morador },
      });
      await modal.present();
      modal.onWillDismiss().then(async (response) => {
        if (response.data && response.data.status) {
          await this.loadFamiliares();
        }
      });
    }
  }

  getFoto(autor) {
    if (autor && autor.foto && autor.foto.foto) {
      return autor.foto.foto;
    } else {
      return "assets/img/avatarDefault.png";
    }
  }

  async modalNotificacoes(){
    const modal = await this.modalController.create({
      component: NotificacoesConfigPage,
    });
    await modal.present();
  }
}
