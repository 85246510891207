export const environment = {
  production: true,
  autUrl: "https://aut.controlcondo.com.br:8883/api/",
  appVersion: "3.0.0",
  appVersionNumber: 300,
  id_integrador: 1,
  mqtt: {
    server: "aut.controlcondo.com.br",
    protocol: "wss", 
    port: 59894,
    username: 'YR8Bq79QfwWxLPY'
  },
  editorConfig: {
    editable: true,
    minHeight: "150px",
    toolbarHiddenButtons: [
      [
        "undo",
        "redo",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
        "insertUnorderedList",
        "insertOrderedList",
        "heading",
        "fontName",
      ],
      [
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  },
};
