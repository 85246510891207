import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { LoadingController, Platform, AlertController } from "@ionic/angular";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
  selector: "app-upload-button",
  templateUrl: "./upload-button.component.html",
  styleUrls: ["./upload-button.component.scss"]
})
export class UploadButtonComponent implements OnInit {
  @Output() onPictureSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: string;
  @Input() color: string;
  @Input('icon') icon: string;
  @Input() fileTypes : any = ['jpeg', 'jpg'];
  idInputFile;

  constructor(
    private helpersService: HelpersService,
    private alertController: AlertController,
    private actionSheet: ActionSheet,
    private camera: Camera,
    private platform: Platform,
    public loadingController: LoadingController,
  ) { }

  ngOnInit() {
    this.idInputFile = Math.random();
  }

  async choosePictures() {
    if(this.helpersService.getDeviceType() !== 'browser'){
      this.mobileGetPicture()
    } else {
      this.browserGetPicture()
    }
  }

  browserGetPicture() {
    let element: HTMLElement = document.getElementById(
      this.idInputFile
    ) as HTMLElement;
    element.click();
  }



  async processUpload(picture) {
    this.onPictureSelected.emit(picture);
  }

  async mobileGetPicture() {
    let buttonLabels = ['Câmera', 'Galeria'];

    const options: ActionSheetOptions = {
      title: 'Enviar imagem para o perfil',
      subtitle: 'Escolher opção',
      buttonLabels: buttonLabels,
      addCancelButtonWithLabel: 'Cancelar'
    }

    this.actionSheet.show(options).then((buttonIndex: number) => {
      if (buttonIndex === 1) {
        this.pictureFromCamera();
      } else if (buttonIndex === 2) {
        this.pictureFromGallery();
      }
    });
  }

  async pictureFromCamera() {
    const options: CameraOptions = {
      quality: 70,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      allowEdit: false,
      cameraDirection: this.camera.Direction.BACK,
      correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
      this.processUpload({base64: 'data:image/jpeg;base64,'+imageData, ext: 'jpeg'});
    }, (err) => {
    });
  }

  async pictureFromGallery() {
    const options: CameraOptions = {
      quality: 70,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM,
      allowEdit: false,
      cameraDirection: this.camera.Direction.BACK,
      correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
      this.processUpload({base64: 'data:image/jpeg;base64,'+imageData, ext: 'jpeg'});
    }, (err) => {
    });
  }

  validateImage(item) {
    //if (item === "png") return true;
    for(let i = 0; i < this.fileTypes.length; i++){
      if (item === this.fileTypes[i]) return true;
    }
    return false;
  }

  async browser(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.readFile(fileInput.target.files[0]).then(async (fileContents : any) => {
        let ext = fileContents.split('/')[1].split(';')[0];
        if(this.validateImage(ext)){
          this.onPictureSelected.emit({base64: fileContents, ext});
        } else {
          const alert = await this.alertController.create({
            header: "Erro",
            message: 'Arquivos do tipo ' + ext + ' não são permitidos.',
            buttons: ["OK"]
          });
          alert.present();
        }
      });
    }
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error("No file to read.");
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
}
