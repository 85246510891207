export const telefoneFixoPattern = '(99) 9999-9999';
export const telefoneCelularPattern = '(99) 99999-9999';
export const horaPattern = '99:99';
export const cpfPattern = '999.999.999-99';
export const cnpjPattern = '99.999.999/9999-99';
export const cepPattern = '99999-999';
export const licensePlatePattern = 'AAA-9S99';

export const cepPlaceholder = '00000-000';
export const cnpjPlaceholder = '00.000.000/0000-00';
export const cpfPlaceholder = '000.000.000-00';
export const pesoPlaceholder = '0,00 kg';
export const dinheiroPlaceholder = 'R$ 0,00';
export const percentualPlaceholder = '0%';
export const horaPlaceholder = 'HH:MM';
export const licensePlatePlaceholder = 'ABC-1B23';

export const dinheiroPatternConfig = {
  precision: 2,
  separator: ',',
  delimiter: '.',
  unit: 'R$',
  zeroCents: false,
};

export const percentualPatternConfig = {
  precision: 1,
  separator: ',',
  delimiter: ',',
  suffixUnit: '%',
  zeroCents: false,
};