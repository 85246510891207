import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Morador } from 'src/app/entities/morador';
import { MinhacontaService } from 'src/app/services/minhaconta.service';

@Component({
  selector: 'app-familiar-create',
  templateUrl: './familiar-create.page.html',
  styleUrls: ['./familiar-create.page.scss'],
})
export class FamiliarCreatePage implements OnInit {
  morador: any = {
    new_foto: '',
    nome: ''
  }
  userPicture: String = "assets/img/avatarDefault.png";
  parentescos: any = [];
  loader;
  constructor(
    private alertController: AlertController,
    private minhacontaService: MinhacontaService,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loadParentescos();
  }

  async loadParentescos(){

    this.minhacontaService.parentescos().then((response) => {
      this.parentescos = response;
    })
  }
  async responseAvatar(picture) {
    this.morador.new_foto = picture.base64;
    this.userPicture = picture.base64;
  }

  async save() {
    this.loader = await this.loadingController.create({
      message: "Cadastrando familiar...",
    });
    await this.loader.present();
    this.morador.usar_control_condo = this.morador.usar_control_condo ? 1 : 0;
    await this.minhacontaService
      .saveFamiliar(this.morador)
      .then((response) => {
        
        setTimeout(() => {
          this.loader.dismiss();
          this.modalController.dismiss({ status: 'account_updated'});
        }, 300)
      })
      .catch(async (errors) => {
        this.loader.dismiss();
        
        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"],
        });
        alert.present();
      });

    this.loader.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
