import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import axios from "axios";
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import config from "whitelabel/ts/config";
import { environment } from 'whitelabel/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  apiAutPrefix        = environment.autUrl; 

  constructor(
    private alertController: AlertController,
    private router: Router,
    private storageService: StorageService,
  ) { }

  apiAut(url, params: any = {}, method = "GET"){
    let fullurl = this.apiAutPrefix + url;
    if(!environment.production){
      fullurl = environment.autUrl + url;
    }
    return this.api(fullurl, params, method)
  }
  apiServer(url, params: any = {}, method = "GET"){
    let fullurl;
    const usuario_condominio = this.storageService.get('usuario_condominio');
    const server = usuario_condominio.condominio ? usuario_condominio.condominio.server : false;
    if(server){
      if(environment.production){
        if(url.includes("master/")){
          fullurl = environment.autUrl + url;
        } else {
          fullurl = server.http+":8883/api/" + url;
        }
      } else {
        if(url.includes("master/")){
          fullurl = environment.autUrl + url;
        } else { 
          fullurl = server.http+"/api/" + url;
        }
      }
    } else {
      fullurl = environment.autUrl + url;
    }
    
    return this.api(fullurl, params, method)
  } 

  api : any = async (fullurl, params: any = {}, method = "GET") => {
    const user_token = this.storageService.get("user_token");
    const usuario_condominio = this.storageService.get('usuario_condominio');
    const token_type = this.storageService.get("token_type");

    if(!params.id_condominio){
      params.id_condominio= usuario_condominio.id_condominio;
    }

    params.id_integrador = environment.id_integrador;
  
    if(config && config.id_condominio){
      params.id_condominio = config.id_condominio;
    }

    params.rand = Math.random() * (9999 - 1111) + 1111;
    params.token_type = token_type;

    const RQ: any = {
      method: method,
      url: fullurl,
      headers: {
        Authorization: "Bearer " + user_token
      }
    };

    if (params.uploadFile) {
      RQ.headers = {
        Authorization: "Bearer " + user_token,
        "Content-Type": "multipart/form-data"
      };
    }

    if(params.uploadForm){
      RQ.params = params;
    }
    if (method === "POST" || method === "PUT") {
      RQ.data = params;
    } else {
      RQ.params = params;
    }

    RQ.timeout = params.setTimeout ? params.setTimeout : 1800000;

    if(params.trackUpload){
      return {data: { RQ, fullurl}};
    } else {
      return axios(fullurl, RQ).catch(async(err) => {
        if(err && err.response && err.response.data &&  err.response.data.status == 'invalid_token'){
          await this.apiAut("auth/logout", {}, "POST");
          localStorage.clear();
          this.router.navigate(['/'], { replaceUrl: true});
          const alert = await this.alertController.create({
            header: "Ops",
            message: "Acesso expirado.",
            buttons: ["OK"]
          });
          await alert.present();
          alert.onDidDismiss().then(() => {
            window.location.reload();
          });
        }
        throw err.response;
      });
    }
  };
  
  external = async (url, params = {}, method = "GET") => {
    const RQ: any = {
      method: method,
      params: params
    };
    return axios(url, RQ);
  };
}
