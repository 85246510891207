<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Trocar condomínio</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item button *ngFor="let c of permissions" (click)="selectCondominio(c)"  class="condominio_select id_{{ c.id_condominio }}">
    <ion-label text-wrap>
      <ion-badge [color]="getColor(c.tipo_usuario)">{{ c.tipo_usuario }}</ion-badge><br>
      {{ c.condominio ? c.condominio.nome : 'Sem nome' }}</ion-label>
    <ion-icon name="radio-button-off-outline" end style="margin-right: 15px"></ion-icon>
  </ion-item>
</ion-content>
