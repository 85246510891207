import { Component, Input, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { MinhacontaService } from "src/app/services/minhaconta.service";

@Component({
  selector: "app-meu-perfil-editar",
  templateUrl: "./meu-perfil-editar.page.html",
  styleUrls: ["./meu-perfil-editar.page.scss"],
})
export class MeuPerfilEditarPage implements OnInit {
  @Input("morador") morador: any = {
    nome: "",
  };
  password_update: Boolean = false;
  passwords = {
    default: "",
    confirm: "",
  };
  loader;
  email: '';
  canEdit: Boolean = false;
  constructor(
    private alertController: AlertController,
    private minhacontaService: MinhacontaService,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {
        const { data } = response;
        this.canEdit = data.morador_morador === 1 ? true : false;
      })
      .catch((err) => {});
  }

  validatePassword() {
    let message = "";
    if (this.password_update) {
      if (this.passwords.default === "" || this.passwords.default.length < 6) {
        message = "A senha precisa ter pelo menos 6 dígitos";
      } else if (this.passwords.default !== this.passwords.confirm) {
        message = "As senhas não estão iguais.";
      }
    }

    return message;
  }

  async save() {
    if (this.validatePassword()) {
      const alert = await this.alertController.create({
        header: "Ops",
        message: this.validatePassword(),
        buttons: ["OK"],
      });
      alert.present();
    } else {
      this.loader = await this.loadingController.create({
        message: "Atualizando perfil...",
      });
      await this.loader.present();
      this.morador.password = this.passwords.default;
      await this.minhacontaService
        .update(this.morador)
        .then(async () => {
          this.loader.dismiss();
          this.minhacontaService.reload();
          this.modalController.dismiss({ status: "account_updated" });
        })
        .catch(async (errors) => {
          this.loader.dismiss();

          let errorStr = "";
          for (var key in errors) {
            errorStr += `<p>` + errors[key] + `</p>`;
          }

          const alert = await this.alertController.create({
            header: "Ops",
            message: errorStr,
            buttons: ["OK"],
          });
          alert.present();
        });
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
