import { Injectable } from "@angular/core";
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MqttTopicsService {
  constructor(private _mqttService: MqttService) {}

  public topic(deviceId: string): Observable<IMqttMessage> {
    let topicName = `/${deviceId}`;
    return this._mqttService.observe(topicName);
  }

  public publish(topic, message: any = {}) {
    return this._mqttService.publish(`/${topic}`, message).toPromise();
  }
}
