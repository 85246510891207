import { Component, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/services/helpers.service';
import {clearCache} from "clear-cache";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { ModalController } from '@ionic/angular';
import settings_ from "whitelabel/ts/config";

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.page.html',
  styleUrls: ['./app-update.page.scss'],
})
export class AppUpdatePage implements OnInit {
  deviceType : any = "";
  message: any = "";
  settings = settings_; 
  constructor(
    private modalController: ModalController,
    private iab: InAppBrowser,
    private helpersService: HelpersService,
  ) { }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  ionViewWillEnter(){
    this.deviceType = this.helpersService.getDeviceType();
    if(this.deviceType === "browser"){
      this.message = "Uma nova atualização está disponível para o Control Condo Web, clique no botão abaixo para atualizar.";
    } else if(this.deviceType === "android"){
      this.message = "Uma nova atualização está disponível para o Control Condo Android, clique no botão abaixo para atualizar.";
    } else if(this.deviceType === "ios"){
      this.message = "Uma nova atualização está disponível para o Control Condo iOS, clique no botão abaixo para atualizar.";
    }
  }

  updateBrowser(){
    clearCache();
  }

  updateMobileIos(){
    this.iab.create("http://apple.co/3pDt80p", "_system");
  }

  updateMobileAndroid(){
    this.iab.create("http://bit.ly/2L36Fuu", "_system");
  }

}
