import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";
import { AlertController, Platform } from "@ionic/angular";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class HelpersService {
  constructor(
    private alertController: AlertController,
    private router: Router,
    private storageService: StorageService,
    private platform: Platform,
    private apiRequest: ApiRequestService
  ) {}

  async formatPermissions(pages, pData, grupos: any = false) {
    if (grupos) {
      let hasAdminPermission = false;

      grupos.map((grupo) => {
        if(grupo > 1){
          hasAdminPermission = true;
        }
      });

      if (hasAdminPermission) {
      } else {
        window.localStorage.clear();
        const alert = await this.alertController.create({
          header: "Ops",
          message: "Você não tem acesso a essa área.",
          buttons: ["OK"],
        });
        alert.present();
        setTimeout(() => {
          this.router.navigate(["login"], { replaceUrl: true });
        });
      }
    }
 
    pages[0].enabled = pData.config_comunicados >= 1 ? true : false;
    pages[1].enabled = pData.config_categorias >= 1 ? true : false;
    pages[2].enabled = pData.config_documentos >= 1 ? true : false;
    pages[3].enabled = pData.config_moradores >= 1 ? true : false;
    pages[4].enabled = pData.config_funcionarios >= 1 ? true : false;
    pages[5].enabled = pData.config_unidades >= 1 ? true : false;
    pages[6].enabled = pData.config_reservas >= 1 ? true : false;
    pages[7].enabled = pData.config_ocorrencia >= 1 ? true : false;
    pages[8].enabled = pData.config_enquete >= 1 ? true : false;
    pages[9].enabled = pData.config_correspondencia_adm >= 1 ? true : false;
    pages[10].enabled = pData.config_assembleias_adm >= 1 ? true : false;
    pages[10].enabled = false;
    pages[11].enabled = pData.config_pet >= 1 ? true : false;
    pages[12].enabled = pData.config_pet >= 1 ? true : false;
    pages[13].enabled = pData.config_fornecedores >= 1 ? true : false;
    //pages[13].enabled = false;
    pages[14].enabled = pData.config_contratos >= 1 ? true : false;
    pages[14].enabled = false;
    pages[15].enabled = pData.config_grupos >= 1 ? true : false;
    pages[16].enabled = pData.config_mudanca >= 1 ? true : false;
    pages[17].enabled = pData.config_patrimonio >= 1 ? true : false;
    pages[17].enabled = false;
    pages[18].enabled = pData.config_agenda_tarefa >= 1 ? true : false;
    pages[18].enabled = false;
    pages[19].enabled = pData.config_atendimento >= 1 ? true : false;
    pages[20].enabled = pData.config_saida_materais >= 1 ? true : false;
    pages[21].enabled = pData.config_mobile_device >= 1 ? true : false;
    return pages;
  }

  formatPeriodo(periodo) {
    let string = "";
    if (periodo.d > 0) {
      string = string + periodo.d + (periodo.d > 1 ? " dias " : " dia ");
    }

    if (periodo.h > 0) {
      string = string + periodo.h + (periodo.h > 1 ? " horas " : " hora ");
    }

    if (periodo.i > 0) {
      string = string + periodo.i + (periodo.i > 1 ? " minutos " : " minuto ");
    }

    if (periodo.s > 0) {
      string =
        string + periodo.s + (periodo.s > 1 ? " segundos " : " segundo ");
    }

    return string;
  }

  encrypt(params) {
    return this.apiRequest
      .apiServer("encrypt", params, "POST")
      .then((response) => {
        return response.data;
      });
  }

  decrypt(params) {
    return this.apiRequest
      .apiServer("decrypt", params, "POST")
      .then((response) => {
        return response.data;
      });
  }

  getAppRemoveVersion() {
    return this.apiRequest
      .apiAut("appVersion")
      .then((response) => {
        return response.data;
      });
  }

  public qrcode = async (code, params) => {
    return this.apiRequest
      .apiServer("qrcode/" + code, params)
      .then((response) => {
        return response.data;
      });
  };

  public marcas = async (params: any = {}) => {
    return this.apiRequest
      .apiServer("veiculos-marcas", params)
      .then((response) => {
        return response.data;
      });
  };

  public modelos = async (params: any = {}) => {
    return this.apiRequest
      .apiServer("veiculos-modelos", params)
      .then((response) => {
        return response.data;
      });
  };

  public cores = async (params: any = {}) => {
    return this.apiRequest
      .apiServer("veiculos-cores", params)
      .then((response) => {
        return response.data;
      });
  };

  searchVehicle(params) {
    return this.apiRequest
      .apiServer("searchVehicle", params, "POST")
      .then((response) => {
        return response.data;
      });
  }

  async urlLinkLiberacao() {
    return this.apiRequest.apiServer("linkLiberacaoUrl").then((response) => {
      return response.data.url;
    });
  }

  async urlLinkLiberacaoRecorrente() {
    return this.apiRequest.apiServer("linkLiberacaoUrlRecorrente").then((response) => {
      return response.data.url;
    });
  }

  saveVehicleAcionador(params) {
    return this.apiRequest
      .apiServer("saveVehicleAcionador", params, "POST")
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        throw response.data;
      });
  }

  emailValidator(params) {
    return this.apiRequest
      .apiServer("emailValidator", params, "POST")
      .then((response) => {
        return response.data;
      });
  }

  adminCountersMorador() {
    return this.apiRequest
      .apiServer("administracao/counters")
      .then((response) => {
        return response.data;
      });
  }

  termosDeUso() {
    return this.apiRequest.apiServer("termosDeUso").then((response) => {
      return response.data;
    });
  }

  formatAutor(autor) {
    let autor_grupos = [];
    if (autor && autor.grupos.length) {
      let grupos = autor.grupos;
      grupos.sort(function (a, b) {
        return b.id_grupo - a.id_grupo;
      });
      autor.grupos = grupos;
      for (var i = 0; i < autor.grupos.length; i++) {
        const item = autor.grupos[i];
        if (item.id_grupo === 3) {
          autor_grupos.push(item);
          break;
        } else if (item.id_grupo === 2) {
          autor_grupos.push(item);
          break;
        } else if (item.id_grupo === 4) {
          autor_grupos.push(item);
          break;
        } else if (item.id_grupo === 5) {
          autor_grupos.push(item);
          break;
        } else if (item.id_grupo === 6) {
          autor_grupos.push(item);
          break;
        } else if (item.id_grupo >= 1) {
          autor_grupos.push(item);
          break;
        }
      }

      autor.grupos = autor_grupos;

      return autor;
    }
  }

  public setTokenType() {
    let token_type = "";
    if (this.getDeviceType() === "browser") {
      token_type = "browser";
    } else {
      token_type = "app";
    }

    setTimeout(() => {
      this.storageService.set("token_type", token_type);
    }, 500);
  }

  getDeviceType() {
    let url = window.location.href;
    if (
      url.includes("localhost:8100") ||
      url.includes("localhost:4200") ||
      url.includes(".com.br") || 
      url.includes("vercel") || 
      url.includes(".com") ||
      url.includes(".app")
    ) {
      return "browser";
    } else {
      if (this.platform.is("android")) {
        return "android";
      } else if (this.platform.is("ios")) {
        return "ios"; 
      }
    }
  }
  public dadosCnpj = async (cnpj) => {
    return this.apiRequest.apiAut("dadosCnpj/" + cnpj);
  };

  moneyValidation(str) {
    let newStr = this.filterNum(str);
    let value = "";
    for (var i = 0; i < newStr.length; i++) {
      if (newStr.length < 3) {
        return "R$ " + newStr;
      } else {
        if (i === newStr.length - 2) {
          value = value + "," + newStr[i];
        } else if (i === newStr.length - 5) {
          if (newStr.length > 5) {
            value = value + "." + newStr[i];
          } else {
            value = value + newStr[i];
          }
        } else {
          value = value + newStr[i];
        }
      }
    }

    return "R$ " + value;
  }

  private filterNum = (str) => {
    const numericalChar = new Set([
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ]);
    str = str
      .split("")
      .filter((char) => numericalChar.has(char))
      .join("");
    return str;
  };

  getSituacaoColor(id_situacao) {
    let color = "primary";
    if (id_situacao >= 1) {
      return "primary";
    }
    if (id_situacao === 2) {
      return "danger";
    }
    if (id_situacao === 3) {
      return "pallete4";
    }
    if (id_situacao === 4) {
      return "tertiary";
    }
    if (id_situacao === 5) {
      return "medium";
    }
    if (id_situacao === 6) {
      return "pallete6";
    }
    if (id_situacao === 7) {
      return "pallete7";
    }
    if (id_situacao === 8) {
      return "pallete8";
    }
    if (id_situacao === 9) {
      return "dark";
    }
    if (id_situacao >= 10) {
      return "success";
    }
    return color;
  }
}
