<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Editar perfil</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item>
    <ion-label position="floating">Nome</ion-label>
    <ion-input
      name="nome"
      type="text"
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      [ngModel]="morador.nome"
      (ngModelChange)="morador.nome=$event"
      required
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating">E-mail</ion-label>
    <ion-input
      [disabled]="true"
      name="email"
      type="text"
      [(ngModel)]="morador.email"
      required
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating">RG</ion-label>
    <ion-input
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      name="RG"
      type="text"
      [(ngModel)]="morador.RG"
      required
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating">CPF</ion-label>
    <ion-input
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      name="CPF"
      type="text"
      [ngModel]="morador.telefone | cpfValidator"
      (ngModelChange)="morador.telefone=$event"
      maxlength="14"
      required
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label>Sexo</ion-label>
    <ion-select
      [disabled]="!canEdit"
      [(ngModel)]="morador.masculino"
      value=""
      okText="Selecionar"
      cancelText="Cancelar"
    >
      <ion-select-option [value]="1">Masculino</ion-select-option>
      <ion-select-option [value]="0">Feminino</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Data Nascimento</ion-label>
    <ion-datetime
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      cancelText="Cancelar"
      doneText="Selecionar"
      displayFormat="DD/MM/YYYY"
      value=""
      [(ngModel)]="morador.nascimento_raw"
      required
    ></ion-datetime>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Atualizar senha</ion-label>
    <ion-toggle
      [disabled]="!canEdit"
      [(ngModel)]="password_update"
    ></ion-toggle>
  </ion-item>
  <ion-item *ngIf="password_update">
    <ion-label position="floating">Nova Senha</ion-label>
    <ion-input
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      name="password_default"
      type="password"
      [(ngModel)]="passwords.default"
      required
    ></ion-input>
  </ion-item>
  <ion-item *ngIf="password_update">
    <ion-label position="floating">Confirmar nova senha</ion-label>
    <ion-input
      [readonly]="!canEdit"
      [disabled]="!canEdit"
      name="password_confirm"
      type="password"
      [(ngModel)]="passwords.confirm"
      required
    ></ion-input>
  </ion-item>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="canEdit">
    <ion-fab-button color="success" (click)="save()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
