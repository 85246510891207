import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";

@Injectable({
  providedIn: "root",
})
export class MenuLateralService {
  constructor(private settingsService: SettingsService) {}
  async moradorPagesUpdate(pages) {
    let keysSearch = [];
    this.moradorPages().map((itemMenu) => {
      keysSearch.push('label_' + itemMenu.id);
    });
    await this.settingsService
      .list({ keys: keysSearch })
      .then((settings) => {
        settings.map((item) => {
          pages.map((menuItem) => {
            if(item.value !== ''){
              let tagFinal = item.tag.replace('label_', '');
              if (tagFinal == menuItem.id){
                menuItem.title = item.value
              }
            }
          })
        });
      })
      .catch((err) => {});
  }
  moradorPages() {
    return [
      {
        id: "atendimento",
        title: "Atendimento",
        url: "/atendimento",
        icon: "logo-whatsapp",
        enabled: false,
      },
      {
        id: "home",
        title: "Home",
        url: "/home",
        icon: "home",
        enabled: true,
      },
      /*
      {
        id: 'assembleias',
        title: "Assembléias",
        url: "/assembleias",
        icon: "bar-chart",
        enabled: false
      }, */
      {
        id: "areas_comuns",
        title: "Reservas",
        url: "/areas-comuns",
        icon: "calendar",
        enabled: false,
      },
      {
        id: "cameras",
        title: "Câmeras",
        url: "/cameras",
        icon: "videocam",
        enabled: false,
      },
      {
        id: "chat",
        title: "Chat",
        url: "/chat",
        icon: "chatbubble",
        enabled: false,
      },
      {
        id: "comunicados",
        title: "Comunicados",
        url: "/comunicados",
        icon: "megaphone",
        enabled: false,
      },
      {
        id: "documentos",
        title: "Documentos",
        url: "/documentos",
        icon: "document",
        enabled: false,
      },
      {
        id: "enquetes",
        title: "Enquetes",
        url: "/enquetes",
        icon: "pie-chart",
        enabled: false,
      },
      {
        id: "entregas",
        title: "Entregas",
        url: "/entregas",
        icon: "mail",
        enabled: false,
      },
      {
        id: "visitas",
        title: "Visitas",
        url: "/liberacao",
        icon: "people",
        enabled: false,
      },
      {
        id: "pets",
        title: "Pets",
        url: "/pets",
        icon: "paw",
        enabled: false,
      },
      {
        id: "mudancas",
        title: "Mudanças",
        url: "/mudancas",
        icon: "trail-sign",
        enabled: false,
      },
      {
        id: "ocorrencias",
        title: "Ocorrências",
        url: "/ocorrencias",
        icon: "alert-circle",
        enabled: false,
      },
      {
        id: "saida_materiais",
        title: "Saída de materiais",
        url: "/saida-materiais",
        icon: "grid",
        enabled: false,
      },
      {
        id: "privacidade",
        title: "Privacidade",
        url: "/privacidade",
        icon: "shield-checkmark",
        enabled: false,
      },
      {
        id: "ajuda",
        title: "Ajuda",
        url: "/ajuda",
        icon: "help-buoy",
        enabled: true,
      },
      {
        id: "configuracoes",
        title: "Configurações",
        url: "/configuracoes",
        icon: "cog",
        enabled: false,
      },
    ];
  }

  masterPages() {
    return [
      {
        title: "Home",
        url: "/home-loader",
        icon: "home",
        enabled: true,
      },
      {
        title: "Integradores",
        url: "/integradores",
        icon: "build",
        enabled: true,
      },
      {
        title: "Usuários",
        url: "/usuarios-list",
        icon: "people",
        enabled: true,
      },
      {
        title: "Condomínios",
        url: "/condominios",
        icon: "business",
        enabled: true,
      },
      {
        title: "FC licenças",
        url: "/fc-licencas",
        icon: "bookmarks",
        enabled: true,
      },
      /*
      ,
      {
        title: "Migração",
        url: "/migracao",
        icon: "server",
        enabled: true
      }
      */
    ];
  }

  administradorPages() {
    return [
      {
        id: "configuracoes-adm",
        title: "Configurações",
        url: "/administracao/configuracoes",
        icon: "cog",
        enabled: true,
      },
      {
        id: "categorias-adm",
        title: "Categorias",
        url: "/categorias-adm",
        icon: "bookmarks",
        enabled: true,
      },
      {
        id: "documentos-adm",
        title: "Documentos",
        url: "/documentos-adm",
        icon: "document",
        enabled: true,
      },
      {
        id: "funcionarios-adm",
        title: "Funcionários",
        url: "/funcionarios-adm",
        icon: "briefcase",
        enabled: true,
      },
      {
        id: "unidades-adm",
        title: "Unidades",
        url: "/unidades-adm",
        icon: "business",
        enabled: true,
      },
      {
        id: "areas-comuns-adm",
        title: "Áreas comuns",
        url: "/areas-comuns-adm",
        icon: "calendar",
        enabled: true,
      },
      {
        id: "grupos-adm",
        title: "Grupos",
        url: "/grupos-adm",
        icon: "people-circle",
        enabled: true,
      },
    ];
  }

  funcionarioPages() {
    return [
      {
        id: "home",
        title: "Home",
        url: "/home-loader",
        icon: "home",
        enabled: true,
      },
      {
        id: "atendimento-adm",
        title: "Atendimento",
        url: "/atendimento-adm",
        icon: "logo-whatsapp",
        enabled: false,
      },
      {
        id: "comunicados-adm",
        title: "Comunicados",
        url: "/comunicados-adm",
        icon: "megaphone",
        enabled: false,
      },
      {
        id: "categorias-adm",
        title: "Categorias",
        url: "/categorias-adm",
        icon: "bookmarks",
        enabled: false,
      },
      {
        id: "documentos-adm",
        title: "Documentos",
        url: "/documentos-adm",
        icon: "document",
        enabled: true,
      },
      {
        id: "moradores-adm",
        title: "Moradores",
        url: "/moradores-adm",
        icon: "people",
        enabled: false,
      },
      {
        id: "funcionarios-adm",
        title: "Funcionários",
        url: "/funcionarios-adm",
        icon: "briefcase",
        enabled: false,
      },
      {
        id: "unidades-adm",
        title: "Unidades",
        url: "/unidades-adm",
        icon: "business",
        enabled: false,
      },
      {
        id: "mudancas",
        title: "Mudanças",
        url: "/mudancas-adm",
        icon: "trail-sign",
        enabled: true,
      },
      {
        id: "areas-comuns-adm",
        title: "Áreas comuns",
        url: "/areas-comuns-adm",
        icon: "calendar",
        enabled: false,
      },
      {
        id: "ocorrencias-adm",
        title: "Ocorrências",
        url: "/ocorrencias-adm",
        icon: "alert-circle",
        enabled: false,
      },
      {
        id: "enquetes-adm",
        title: "Enquetes",
        url: "/enquetes-adm",
        icon: "pie-chart",
        enabled: false,
      },
      {
        id: "correspondencia-adm",
        title: "Entregas",
        url: "/correspondencias-adm",
        icon: "mail-unread",
        enabled: false,
      },
      {
        id: "pets-adm",
        title: "Pets",
        url: "/pets-adm",
        icon: "paw",
        enabled: false,
      },
      {
        id: "especies-adm",
        title: "Espécies",
        url: "/especies-adm",
        icon: "bug",
        enabled: false,
      },
      {
        id: "agenda-adm",
        title: "Agenda de tarefas",
        url: "/agenda-adm",
        icon: "list-circle",
        enabled: false,
      },
      {
        id: "fornecedores-adm",
        title: "Fornecedores",
        url: "/fornecedores-adm",
        icon: "construct",
        enabled: false,
      },
      {
        id: "contratos-adm",
        title: "Contratos",
        url: "/contratos-adm",
        icon: "document-text",
        enabled: false,
      },
      {
        id: "grupos-adm",
        title: "Grupos",
        url: "grupos-adm",
        icon: "people-circle",
        enabled: false,
      },
      {
        id: "mudanca-adm",
        title: "Mudanças",
        url: "/mudancas-adm",
        icon: "trail-sign",
        enabled: false,
      },
      {
        id: "patrimonio-adm",
        title: "Patrimônio",
        url: "/patrimonios-adm",
        icon: "file-tray-stacked",
        enabled: false,
      },
      {
        id: "saida-materiais-adm",
        title: "Saída de Materiais",
        url: "/saida-materiais-adm",
        icon: "grid",
        enabled: false,
      },
      {
        id: "leitoresmobile-adm",
        title: "Leitores mobile",
        url: "/leitor-mobile-adm",
        icon: "phone-portrait",
        enabled: false,
      },
      /*
      {
        id: "assembleias-adm",
        title: "Assembléias",
        url: "/assembleias-adm",
        icon: "bar-chart",
        enabled: false
      }
      */
    ];
  }
}
