import { NgModule } from "@angular/core";
import { DateTimeToStringPipe } from "./date-time-to-string.pipe";
import { UcFirstLetterPipe } from "./uc-first-letter.pipe";
import { CpfValidatorPipe } from "./cpf-validator.pipe";
import { CnpjValidatorPipe } from "./cnpj-validator.pipe";
import { PhoneValidatorPipe } from "./phone-validator.pipe";
import { CepValidatorPipe } from './cep-validator.pipe';
import { TimestampToStringPipe } from './timestamp-to-string.pipe';
import { LicensePlatePipe } from './license-plate.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { MoneyValidatorPipe } from './money-validator.pipe';
import { ReversePipe } from './reverse.pipe';
import { StrToUpperPipe } from './str-to-upper.pipe';
import { AlphaNumericPipe } from './alpha-numeric.pipe';

@NgModule({
  declarations: [
    DateTimeToStringPipe,
    LicensePlatePipe,
    UcFirstLetterPipe,
    CpfValidatorPipe,
    CnpjValidatorPipe,
    TitleCasePipe,
    PhoneValidatorPipe,
    CepValidatorPipe,
    TimestampToStringPipe,
    LicensePlatePipe,
    TitleCasePipe,
    MoneyValidatorPipe,
    ReversePipe,
    StrToUpperPipe,
    AlphaNumericPipe,
  ],
  imports: [],
  exports: [
    DateTimeToStringPipe,
    TitleCasePipe,
    LicensePlatePipe,
    UcFirstLetterPipe,
    CpfValidatorPipe,
    CnpjValidatorPipe,
    PhoneValidatorPipe,
    CepValidatorPipe,
    MoneyValidatorPipe,
    ReversePipe,
    StrToUpperPipe,
    AlphaNumericPipe
  ],
})
export class PipesModule {}
